<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="40%"
    v-on="$listeners">
    <span slot="title">
      <em
        :style="{color:$store.state.currentTheme.primaryColor}"
        class="iconfont icon-yuandianzhong">
      </em>
      {{ $t('changeEmail') }}
    </span>
    <div style="padding: 20px">
      <el-form
        ref="form"
        :model="formData"
        :label-width="$i18n.locale==='cn'?'80px':'160px'">
        <el-form-item
          :label="$t('oldEmail')">
          <el-input
            type="text"
            :value="formData.oldEmail"
            disabled>
          </el-input>
        </el-form-item>
        <el-form-item
          :label="$t('newEmail')"
          prop="email"
          required>
          <el-input v-model="formData.email"></el-input>
        </el-form-item>
        <el-form-item :label="$t('verificationCode')">
          <div style="display: flex">
            <el-input v-model="formData.verifyCode"></el-input>
            <el-button
              style="margin-left: 10px"
              :loading="emailCodeLoading"
              :disabled="emailCodeCountDown>0"
              @click="getCode">
              {{ $t('getCode') }}
              <span v-show="emailCodeCountDown>0">
              {{ `(${emailCodeCountDown})` }}
            </span>
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button
      size="small"
      @click="dialogVisible = false">
      {{ $t('cancel') }}
    </el-button>
    <el-button
      size="small"
      type="primary"
      @click="submit">
      {{ $t('submit') }}
    </el-button>
  </span>
  </el-dialog>

</template>

<script>
import baseMixin from '@/mixins/base-mixin'

export default {
  name: 'ChangeEmailDialog',
  mixins: [baseMixin],
  data () {
    return {
      formData: {
        oldEmail: '',
        email: '',
        verifyCode: '',
        verifyCodeId: ''
      },
      dialogVisible: false,
      emailCodeLoading: false,
      emailCodeCountDown: 0
    }
  },
  mounted () {
    this.formData.oldEmail = this.$store.state.user.email
    this.dialogVisible = this.$attrs.visible
  },
  methods: {

    getCode () {
      /**
       * 获取验证码
       */
      this.$refs.form.validate(valid => {
        if (valid) {
          this.getEmailCode(this.formData.email).then(codeId => {
            this.formData.verifyCodeId = codeId
          })
        }
      })
    },

    submit () {
      /**
       * 提交修改
       */
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$request.post({
            url: this.$apis.changeEmail,
            data: this.formData
          }).then(res => {
            if (res?.code === 1000) {
              this.dialogVisible = false
              this.$message.success(this.$t('operationSuccess'))
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
